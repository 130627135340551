.form {
  margin: 0 auto;
}

.button {
  padding: 4px 36px;
  height: 52px;
}

.center {
  align-self: center;
}

.link {
  margin-bottom: 36px;
  text-align: center;
  font-size: 16px;
  color: #a0a0a0;
  display: block;
}
