.nav {
  width: 100%;
}

.nav__container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.nav__orders {
  display: none;
  text-decoration: none;
}


.menuButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: none;
}

.menuButton img {
  width: 100%;
}

.nav__container-mobile {
  display: none;
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  background: #FFF;
  border-radius: 8px;
  padding: 20px 50px;
  z-index: 3;
  justify-content: space-between; 
}

.nav__container-mobile_visible {
  display: flex;
}

@media screen and (max-width: 770px) {
  .menuButton {
    display: block
  }

  .nav__orders {
    display: block;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .nav__container {
    display: none;
  }
}

@media screen and (max-width: 610px) {
  .nav__container-mobile {
    flex-direction: column-reverse;
  }

  .nav__container-mobile {
    padding: 20px;
  }

}

