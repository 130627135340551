.form {
  display: flex;
  flex-direction: column;
  max-width: 744px;
  padding: 0;
  border: none;
  box-shadow: none;
}

.ingredientsInputs {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ingredientsInput {
  width: 100%;
  max-width: 395px;
}

.ingredientsAmountInputContainer {
  display: flex;
  gap: 8px;
}

.ingredientsAmountInputContainerMob {
  display: none;
}

.measurementUnit {
  align-self: center;
  margin-left: 20px;
}

.cookingTime {
  display: flex;
}

.cookingTimeUnit {
  align-self: center;
  margin-bottom: 20px;
}

.ingredientsAmountValue {
  max-width: 100px;
}

.ingredientsTimeInput {
  margin-right: 10px;
}

.ingredientsTimeValue {
  max-width: 46px;
}

.amountText {
  margin: 10px 0 0;
}

.ingredientsNameInput {
  padding-right: 20px;
  flex: 1;
}

.ingredientsNameInput,
.ingredientsAmountInput,
.measurementUnit {
  margin-bottom: 20px;
}

.ingredientAdd {
  cursor: pointer;
  text-align: left;
  padding-left: 200px;
  margin-bottom: 20px;
}

.ingredientsAdded {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-left: 200px;
  text-align: left;
  margin-bottom: 30px;
}

.fileInput {
  margin-bottom: 60px;
}

.button {
  margin-left: 200px;
  font-size: 18px;
  align-self: flex-start;
}

.ingredientsAddedItemTitle {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.ingredientsAddedItem {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  width: fit-content;
  border: 1px solid #a0a0a0;
  border-radius: 30px;
}

.ingredientsAddedItemRemove {
  cursor: pointer;
  margin-left: 14px;
}

.mb36 {
  margin-bottom: 36px;
}

.error {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #ff3b30;
  margin-top: 8px;
  margin-left: 200px;
  text-align: left;
}

.errorIngredient {
  padding-left: 200px;
  text-align: left;
}

.checkboxGroup {
  margin-bottom: 36px;
}

@media screen and (max-width: 820px) {
  .ingredientsInputs {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .ingredientAdd {
    padding-left: 0px;
  }

  .checkboxGroup {
    display: block;
  }

  .checkboxGroupLabel {
    margin-bottom: 10px;
  }

  .cookingTime {
    text-align: left;
  }

  .cookingTimeUnit {
    position: relative;
    left: -155px;
    align-self: end;
    margin-bottom: 28px;
  }

  .ingredientsAdded {
    padding-left: 0px;
  }

  .ingredientsAmountInputContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .amountText {
    margin: 0 0 8px;
  }

  .measurementUnit {
    align-self: flex-start;
    margin-left: 0;
  }

  .button {
    margin-left: 0;
  }

  .error {
    margin-left: 0;
  }

  .ingredientsNameInput {
    width: 100%;
  }
}

@media screen and (max-width: 730px) {
  .button {
    width: 100%;
  }

  .error {
    text-align: center;
  }
}
