.subscriptionList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 40px;
    margin-bottom: 52px;
}

@media screen and (max-width: 970px) {
  .subscriptionList {
    grid-gap: 20px;
  }
}

@media screen and (max-width: 860px) {
  .subscriptionList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 660px) {
  .subscriptionList {
    grid-template-columns: 1fr;
  }
}