.input {
  margin-bottom: 16px;
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.inputLabel {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.auth {
  flex-direction: column;
  align-items: stretch;
}

.inputLabelText {
  margin-right: 30px;
  text-align: left;
  font-weight: 600;
  width: 170px;
}

.inputField {
  font-family: "SF Pro Display", sans-serif;
  padding: 10px 12px;
  border-radius: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  flex: 1;
  border: 1px solid #a0a0a0;
}

.inputField:focus {
  outline: 2px solid #000;
  border: 1px solid #fff;
}

.inputError {
  outline: 2px solid #ff3b30;
  border: 1px solid #fff;
}

.inputError.inputField:focus {
  outline: 2px solid #ff3b30;
  border: 1px solid #fff;
}

.errorBox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 8px;
}

.error {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #ff3b30;
  margin: 0;
  text-align: left;
}

input::placeholder {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: #a0a0a0;
}

@media screen and (max-width: 820px) {
  .inputLabel {
    display: block;
    text-align: left;
  }

  .inputLabelText {
    margin-bottom: 10px;
  }

  .inputField {
    width: 100%;
  }
}
