.form {
  max-width: 530px;
  background: #fff;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  padding: 60px;
  margin-bottom: 100px;
}

@media screen and (max-width: 730px) {
  .form {
    padding: 40px 20px;
  }
}
