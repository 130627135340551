.form {
  margin: 0 auto;
}

.button {
  margin-top: 16px;
}

.center {
  align-self: center;
}