.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.label {
  min-width: 170px;
  margin-right: 30px;
  text-align: left;
  font-weight: 600;
}

.checkboxGroupItems {
  display: flex;
}

